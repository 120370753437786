// Nav size on scroll
$(window).scroll(function() {
  if ($(this).scrollTop() > 50){  
      $('nav').addClass("sticky");
  }
  else{
      $('nav').removeClass("sticky");
  }
});

// Close mobile menu when clicked outside
$(document).click(function(event) {
  $(event.target).closest(".navbar").length || $(".navbar-collapse.show").length && $(".navbar-collapse.show").collapse("hide")
});